* {
  margin:  0;
  box-sizing: border-box;
}

html {
  font-size:  1px;
}
body {
  font-size:  14rem;
  height: 100vh;
  max-height: 100vh;
}
.app {
  text-align: center;
}