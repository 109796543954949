.home {
  text-align: center;
}
.footer {
  font-size: 15rem;
}
.footer a {
  color: white;
}
.footer >div {
  margin-bottom: 10rem;
}

.logo {
  width: 30%;
  max-height: 100%;
}

.home-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .home-logo {
    animation: home-logo-spin infinite 20s linear;
  }
}

.home-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.home-header h1 {
  color: #AAA;
}


.home-link {
  color: #61dafb;
}

@keyframes home-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
